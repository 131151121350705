import { SESSION_COOKIE } from "@app/consts";
import { Session } from "@app/types";
import Cookies from "js-cookie";

export const getClientAuth = () => {
  try {
    const value = Cookies.get(SESSION_COOKIE);
    return { data: value ? (JSON.parse(value) as Session) : undefined };
  } catch (e) {
    return {};
  }
};
