import { IS_SERVER_CONTEXT } from "../utils/platform";

const getServerApiAddress = () => {
  return `${process.env.API_PROTOCOL}://${process.env.API_HOST}:${process.env.API_PORT}/api`;
};

const getClientApiAddress = () => {
  // on localhost we can't add .api to the domain
  const prefix = window.location.host.includes("localhost") ? "" : "api.";
  const protocol = window.location.protocol.includes("https") ? "https" : "http";
  // on localhost we make requests directly to the api
  const host = window.location.host.includes("localhost")
    ? `localhost:${process.env.NEXT_PUBLIC_API_PORT}`
    : window.location.host;

  return `${protocol}://${prefix}${host}/api`;
};

export const config = {
  getApiPath: () => (IS_SERVER_CONTEXT ? getServerApiAddress() : getClientApiAddress()),
  getAppPath: () => process.env.ADM_ADDRESS,
};
