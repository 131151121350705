import { getClientAuth } from "@app/app/[locale]/auth/clientAuth";
import { signOut } from "@app/auth";
import { config } from "@app/config";
import { IS_SERVER_CONTEXT } from "@ea/ui/api";
import { setApiContext } from "@ea/ui/api/apiContext";

function initContext() {
  setApiContext({
    baseUrl: config.getApiPath(),
    getAuthToken: async () => {
      const session = getClientAuth();
      return session?.data?.authToken;
    },
    signOut,
  });
}

export function initClientContext() {
  if (!IS_SERVER_CONTEXT) {
    initContext();
  }
}
